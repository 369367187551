import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select } from "redux-saga/effects";
import { isAuthenticated } from "./authCrud";
import * as menuActions from "../../ApplicationMenu/_redux/applicationMenuActions";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  LoginWith2Fa: "[LoginWith2Fa] Action",
  setAuthLoading: "[LOADING] ACTION",
  getInfo: "[getInfo] Action",
  TokenRequested: "[Request Token] Action",
  TokenLoaded: "[Token User] Auth API",
};

const initialAuthState = {
  requiresTwoFactor: false,
  authLoading: false,
  loading2Fa: false,
  config: undefined,
  user: undefined,
  authToken: undefined,
};

export const reducer = persistReducer(
  { storage, key: "v706-auth", whitelist: ["config", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { requiresTwoFactor } = action.payload;

        return { authLoading: true, requiresTwoFactor };
      }

      case actionTypes.LoginWith2Fa: {
        return { requiresTwoFactor: true, loading2Fa: true };
      }

      case actionTypes.TokenLoaded: {
        const { token, config } = action.payload;

        return {
          ...initialAuthState,
          authToken: token,
          authLoading: false,
          loading2Fa: false,
          config,
        };
      }

      case actionTypes.setAuthLoading: {
        const { loading } = action.payload;

        return {
          ...initialAuthState,
          authLoading: loading,
          loading2Fa: loading,
        };
      }

      case actionTypes.getInfo: {
        const { user } = action.payload;

        return {
          ...initialAuthState,
          user,
          authLoading: false,
          loading2Fa: false,
        };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (requiresTwoFactor) => ({
    type: actionTypes.Login,
    payload: { requiresTwoFactor },
  }),
  loginMfa: () => ({ type: actionTypes.LoginWith2Fa }),
  logout: () => ({ type: actionTypes.Logout }),
  setLoading: (loading) => ({
    type: actionTypes.setAuthLoading,
    payload: { loading },
  }),
  requestToken: (token) => ({
    type: actionTypes.TokenRequested,
    payload: { token },
  }),
  fulfillInfo: (user) => ({ type: actionTypes.getInfo, payload: { user } }),
  fulfillToken: (token, config) => ({
    type: actionTypes.TokenLoaded,
    payload: { token, config },
  }),
};

const getRequiresTwoFactor = ({ auth }) => auth.requiresTwoFactor;

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginWithoutMfaSaga() {
    const auth = yield select(getRequiresTwoFactor);
    if (!auth) {
      yield put(actions.requestToken());
    }
  });

  yield takeLatest(actionTypes.Logout, function* logout() {
    yield put(menuActions.unControlMenu());
  });

  yield takeLatest(actionTypes.LoginWith2Fa, function* loginSaga() {
    yield put(actions.requestToken());
  });

  yield takeLatest(actionTypes.TokenRequested, function* userRequested() {
    const { data } = yield isAuthenticated();
    const { success, data: token } = data;
    if (success) {
      const config = {
        appMenu: token.config.appMenu,
        isMachineRemembered: token.isMachineRemembered,
        is2faEnabled: token.is2faEnabled,
        accessToken: token.accessToken,
        userInfo: { uid: token.uid, username: token.username },
      };
      yield put(actions.fulfillToken(token.token, config));
    }
  });
}
