import React from 'react';// , { useState }
import { ErrorBoundary } from 'react-error-boundary';
import { Button } from 'react-bootstrap';
// import { Alert } from 'antd';

import Flex from './Flex';
import { Cx } from '../../utils/Q';

export default function ErrorComponent({
  className,
  prefixClass = "alert",
  type = "light",
  // children, 
  ...etc
}) {
  // const [explode, setExplode] = useState(false);// Not work for all errors

  return (
    <ErrorBoundary
      // FallbackComponent={ErrorFallback} 
      fallbackRender={() => ( // { error }
        <Flex
          dir="column"
          justify="center"
          align="center"
          // role="alert" 
          className={
            Cx(prefixClass,
              "py-5 mb-0 rounded-0 text-break ovauto w-100", {
              ["alert-" + type]: type
            }, className)
          }
        >
          <h3 className="text-dark">⚠ Something went wrong.</h3>
          {/* <p>{navigator.onLine ? error.message : "Your internet connection is offline."}</p> */}

          {navigator.onLine ?
            <Button onClick={() => window.location.reload()} variant="outline-primary">Reload</Button>
            :
            <p className="lead mb-0">Your internet connection is offline.</p>
          }

          {/* {navigator.onLine && 
            <Button onClick={() => window.location.reload()} variant="outline-primary">Reload</Button>
          } */}
        </Flex>
      )}
      onError={(err, info) => {
        // setExplode(true);// Not work for all errors
        console.log('onError Component error: ', err);
        console.log('onError Component info: ', info);
      }}
      // onReset={() => setExplode(false)} // Not work for all errors
      // resetKeys={[explode]} // Not work for all errors
      {...etc}
    />
  )
}


