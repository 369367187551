/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";// , { useState }
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import SVG from "react-inlinesvg";

import { toAbsoluteUrl, checkIsActive, getCurrentUrl } from "../../../../_helpers";
import * as actions from "../../../../../app/modules/ApplicationMenu/_redux/applicationMenuActions";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open ` : "";
  };

  const { menus, path } = useSelector(
    ({ auth, applicationMenu }) => ({
      menus: auth.config.appMenu.lists,
      path: applicationMenu.path
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const seed = menus.find(m => {
    return m.href !== '/' && (path ?? getCurrentUrl(location)).includes(m.href) && m.showOnSeed;
  });

  const GenerateRootMenu = (item) => {
    if (item.items && item.items.length && !item.showOnSeed > 0) {
      return (
        item.isGroup ? 
          <React.Fragment key={item.id + "_section"}>
            <li key={item.id} className="menu-section ">
              <h4 className="menu-text">{item.title}</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {item.items.map((v) => GenerateRootMenu(v))}
          </React.Fragment> 
          :
          <li
            key={item.id + "_menu"}
            className={"menu-item menu-item-submenu " + getMenuItemActive(item.href, true)}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to={item.href}>
              <span className="svg-icon menu-icon svg-icon-white">
                <SVG title=" " src={toAbsoluteUrl(item.icon)} />
              </span>
              <span className="menu-text">{item.title}</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">{item.title}</span>
                  </span>
                </li>

                {item.items.map((child) => GenerateMenu(child))}
              </ul>
            </div>
          </li>
      );
    } else {
      return (
        !item.isGroup && 
        <li 
          key={item.id}
          className={"menu-item " + getMenuItemActive(item.href)}
          aria-haspopup="true"
        >
          {item.showOnSeed ? 
            <div className="menu-link" onClick={() => dispatch(actions.controlMenu(item.href))}>
              <span className="svg-icon menu-icon svg-icon-white">
                <SVG title=" " src={toAbsoluteUrl(item.icon)} />
              </span>
              <span className="menu-text">{item.title}</span>

              {item.parent &&
                <span className="badge badge-pill text-center align-self-center badge-warning text-white" title={item.parent}>
                  {item.parent}
                </span>
              }
            </div>
            : 
            <NavLink to={item.href} className="menu-link">
              <span className="svg-icon menu-icon svg-icon-white">
                <SVG title=" " src={toAbsoluteUrl(item.icon)} />
              </span>
              <span className="menu-text">{item.title}</span>

              {item.parent &&
                <span className="badge badge-pill text-center align-self-center badge-warning text-white" title={item.parent}>
                  {item.parent}
                </span>
              }
            </NavLink>
          }
        </li>
      );
    }
  }

  const GenerateMenu = (item) => {
    if (item.items && item.items.length > 0)
      return (
        <li
          key={item.id}
          className={"menu-item menu-item-submenu " + getMenuItemActive(item.href, true)}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to={item.href}>
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">{item.title}</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{item.title}</span>
                </span>
              </li>

              {item.items.map((child) => GenerateMenu(child))}
            </ul>
          </div>
        </li>
      );
    else
      return (
        <li
          key={item.id}
          className={"menu-item " + getMenuItemActive(item.href)}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={item.href}>
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">{item.title}</span>
          </NavLink>
        </li>
      );
  }

  return (
    <ul className={"menu-nav py-0 " + layoutProps.ulClasses}>
      {seed ? (
        <>
          <li
            className="menu-item"
            aria-haspopup="true"
          >
            <NavLink 
              className="menu-link" 
              to="/" 
              onClick={() => dispatch(actions.unControlMenu())}
            >
              <span className="svg-icon menu-icon svg-icon-white">
                <SVG title=" " src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />
              </span>
              <span className="menu-text">Back to Home</span>
            </NavLink>
          </li>
          <li className="menu-section ">
            <h4 className="menu-text">{seed.title}</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          
          {seed.items.map((item) => GenerateRootMenu(item))}
        </>
      )
      : 
      menus.map((item) => GenerateRootMenu(item))}
    </ul>
  );
}
