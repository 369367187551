import axios from 'axios';
export const APP_MENU_URL = "/api/sys/permissions/menu";

export function getTree() {
    return axios.get(`${APP_MENU_URL}/sidebar/GetTree`);
}

export function orderIndex(param) {
    return axios.post(`${APP_MENU_URL}/OrderIndex`, param);
}

export function createMenu(menu) {
    return axios.post(`${APP_MENU_URL}/sidebar/save`, menu);
}

export function deleteMenu(id) {
    return axios.post(`${APP_MENU_URL}/deletebyId`, null, { params: { id } });
}

export function getDetail(id) {
    return axios.get(`${APP_MENU_URL}/${id}/detail`);
}

export function publish(id) {
    return axios.post(`${APP_MENU_URL}/Publish`, id);
}