/** Check support native lazy loading image, iframe */
// const SUPPORT_LOADING = 'loading' in HTMLImageElement.prototype;

// https://medium.com/javascript-in-plain-english/you-must-understand-these-14-javasript-functions-1f4fa1c620e2
/* function cached(fn){
// Create an object to store the results returned after each function execution.
  const cache = Object.create(null);
  // Returns the wrapped function
  return function cachedFn(str){
    // If the cache is not hit, the function will be executed
    if(!cache[str]){
			let result = fn(str);
			// Store the result of the function execution in the cache
			cache[str] = result;
    }
    return cache[str]
  }
} */

/** FROM: Vue.js */
function cached(fn){
	let cache = Object.create(null);
	return (function cachedFn(s){
		let hit = cache[s];
		return hit || (cache[s] = fn(s))
	})
}

/** Number format */
export const numberFormat = (lang = "en-GB", options = {}, value = 1) => new Intl.NumberFormat(lang, options).format(value);

/** === Type checking === */
function isStr(v){
	return typeof v === 'string' || v instanceof String;
}
function isObj(v){
	return v && typeof v === 'object' && v.constructor === Object;
}
function isFunc(v){
	return v && typeof v === 'function';
}
function isNum(v){
	return typeof v === "number" && !isNaN(v);
}
/** === END Type checking === */
/** Q classnames */
function Cx(){
  let hasOwn = {}.hasOwnProperty,
			c = [],
			alength = arguments.length;
  for(let i = 0; i < alength; i++){
		let arg = arguments[i];
		if(!arg) continue;

		/* let argType = typeof arg; */
		if(isStr(arg) || typeof arg === 'number'){
			c.push(arg);
    }
    else if(Array.isArray(arg) && arg.length){
			let inr = Cx.apply(null, arg);
			if(inr) c.push(inr);
		}else if(isObj(arg)){
			for(let k in arg){
				if(hasOwn.call(arg, k) && arg[k]) c.push(k);
			}
		}
  }
  return c.length > 0 ? c.join(' ') : undefined;
}

/** == dom-q.js === */
function domQ(q, dom = document){
  return dom.querySelector(q);
}
/** DEV OPTIONS: only this / return array with check length */
function domQall(q, dom = document){
	return dom.querySelectorAll(q);
}
/** USAGE:
	add = setClass(element, "btn active");
	remove = setClass(element, "btn active", 'remove'); */
function setClass(el, c, fn = "add"){
  let cls = c.split(" ");
  el.classList[fn](...cls);
}
function hasClass(el, c){
  return el.classList.contains(c);
}
function toggleClass(el, c, cek){
  el.classList.toggle(c, cek);
}
function hasAttr(el, a) {
	if (el && a) return el.hasAttribute(a);
	return false;
}
function getAttr(el, a){
  return el.getAttribute(a);
}
/**
	@el : Element / node
	@attr : attribute name & value (Object)
*/
function setAttr(el, attr){
	if(el){
		if(isObj(attr)){
			for(let key in attr){
				el.setAttribute(key, attr[key]);
			}
		}
		else if(isStr(attr)) attr.split(" ").forEach(v => el.removeAttribute(v));
		else console.warn('setAttr() : params 2 required Object to add / string to remove, To remove several attributes, separate the attribute names with a space.');
	}
}
/** === END dom-q.js === */

/** === Generate id === */
function Qid(){
 	function S4(){
  	return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
 	}
 	return 'fs-' + (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + Date.now())
}
/** === END Generate id === */

/** No Action */
function preventQ(e){
  e.preventDefault();
  e.stopPropagation();
}
//function noop(){};
/** END No Action */

/** Bind multiple component methods:
	* @param {this} context
	* @param {Array} functions
	constructor(){
		...
		bindFuncs.call(this,['onClick','onModal']);
	} */
//function bindFuncs(fns){
//	fns.forEach(f => (this[f] = this[f].bind(this)));
//}

/** reactstrap utils */
function omit(obj, omitKeys){
  let res = {};
  Object.keys(obj).forEach(k => {
    if(omitKeys.indexOf(k) === -1) res[k] = obj[k];
  });
  return res;
}
/** END reactstrap utils */

const makeEl = t => document.createElement(t);

/** String Function */
function toCapital(s) {
	return s[0].toUpperCase() + s.slice(1);
}

// FOR check mobile device
function isMobile(){
  return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

// function isSupportLocaleDateString(date){
  // try {
    // date.toLocaleDateString('i');// new Date().toLocaleDateString('i');
  // }catch(e){
    // return e.name === 'RangeError';
  // }
  // return false;
// }

// function isValidLang(lang){
// 	if(!window.Intl) return;
// 	try {
// 		let supportLocOf = Intl.DateTimeFormat.supportedLocalesOf(lang, {localeMatcher:'lookup'});// lookup | best fit
// 		let isOk = supportLocOf && Intl.getCanonicalLocales(lang) ? supportLocOf.length : false;
// 		return isOk;
// 	}catch(e){
// 		console.warn(e.message);// expected output: RangeError: invalid language tag: lang
// 	}
// }

// function dateIntl(dt, options, lang = 'en'){
// 	if(!dt || !isValidLang(lang)) return;// && !isSupportLocaleDateString(dt)

// 	// console.log('isValidLang: ',!isValidLang(lang));
// 	try {
// 		let setOptions = {
// 			weekday:'long', // long | short | narrow
// 			year:'numeric', // numeric | 2-digit
// 			month:'long', // numeric | 2-digit | long | short | narrow
// 			day:'numeric', // numeric | 2-digit
// 			// hour: '', // numeric | 2-digit
// 			// minute: '', // numeric | 2-digit
// 			// second: '', // numeric | 2-digit
// 			...options
// 		};
// 		// console.log(setOptions);

// 		return new Intl.DateTimeFormat(lang, setOptions).format(dt);
// 	}catch(e){
// 		console.warn(e.message);
// 	}
// }

/* function dateToStr(dt){
	let date = new Date(dt);
	return date.toDateString();
} */

/** Check html tagName **/
/* function isTag(t){ // tagCheck
	// let c = document.createElement(t),
			// b = c.toString() !== "[object HTMLUnknownElement]"
  // return {valid:b, el:c};
	return document.createElement(t).toString() !== "[object HTMLUnknownElement]";
} */

//const getRect = (el) => el.getBoundingClientRect();

//const detectElementOverflow = (element, container) => ({
//	get collidedTop() {
//		return getRect(element).top < getRect(container).top;
//	},
//	get collidedBottom() {
//		return getRect(element).bottom > getRect(container).bottom;
//	},
//	get collidedLeft() {
//		return getRect(element).left < getRect(container).left;
//	},
//	get collidedRight() {
//		return getRect(element).right > getRect(container).right;
//	},
//	get overflowTop() {
//		return getRect(container).top - getRect(element).top;
//	},
//	get overflowBottom() {
//		return getRect(element).bottom - getRect(container).bottom;
//	},
//	get overflowLeft() {
//		return getRect(container).left - getRect(element).left;
//	},
//	get overflowRight() {
//		return getRect(element).right - getRect(container).right;
//	},
//});

// const isOverflowing = tbody.clientWidth < tbody.scrollWidth || tbody.clientHeight < tbody.scrollHeight;

//const newURL = (path = "/") => new URL(path, window.location.origin);

// FROM https://github.com/chakra-ui/chakra-ui/blob/master/packages/chakra-ui/src/Avatar/index.js
const getInitials = (name, no = '?') => {
	// if(!name || !Q.isStr(name) || name.length < 1) return no;
	if(!name || !isStr(name) || name === " " || name.length < 1) return no;
	// Destruct 
  let [first, last] = name.split(" ");

  if(first && last){
    return first[0] + last[0]; // `${first[0]}${last[0]}`;// first.charAt(0)}${last.charAt(0)
  }
	return first[0];// first.charAt(0)
};

const formikValidClass = (formik, field) => {
	let touch = formik.touched[field];
	let err = formik.errors[field];
	if (touch && err) {
		return " is-invalid";
	}
	if (touch && !err) {
		return " is-valid";
	}
	return "";
};

const getParam = (key) => new URLSearchParams(window.location.search).get(key);

// Date.prototype.toIsoString = function() {
// 	let tzo = -this.getTimezoneOffset(),
// 			dif = tzo >= 0 ? '+' : '-',
// 			pad = function(num){
// 				let norm = Math.floor(Math.abs(num));
// 				return (norm < 10 ? '0' : '') + norm;
// 			};
// 	return this.getFullYear() +
// 			'-' + pad(this.getMonth() + 1) +
// 			'-' + pad(this.getDate()) +
// 			'T' + pad(this.getHours()) +
// 			':' + pad(this.getMinutes()) +
// 			':' + pad(this.getSeconds()) +
// 			dif + pad(tzo / 60) +
// 			':' + pad(tzo % 60);
// }

function getLocalISOString() {
	// Get local time as ISO string with offset at the end
	let now = new Date();
	let tzo = -now.getTimezoneOffset();
	let dif = tzo >= 0 ? '+' : '-';
	let pad = function(n, width) {
			width = width || 2;
			n = Math.abs(Math.floor(n)) + '';
			return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
	};
	return now.getFullYear() 
			+ '-' + pad(now.getMonth()+1)
			+ '-' + pad(now.getDate())
			+ 'T' + pad(now.getHours())
			+ ':' + pad(now.getMinutes()) 
			+ ':' + pad(now.getSeconds())
			+ '.' + pad(now.getMilliseconds(),3)
			+ dif + pad(tzo / 60) 
			+ ':' + pad(tzo % 60);
}

// const unescapeHTML = str => str.replace(
// 	/&amp;|&lt;|&gt;|&#39;|&quot;/g,
// 	tag => ({
// 			'&amp;': '&',
// 			'&lt;': '<',
// 			'&gt;': '>',
// 			'&#39;': "'",
// 			'&quot;': '"'
// 		}[tag] || tag)
// );
const unescapeHTML = str => str.replace(/&amp;|&#39;|&quot;/g, tag => ({
		'&amp;': '&',
		'&#39;': "'",
		'&quot;': '"'
	}[tag] || tag)
);

export {
	// SUPPORT_LOADING, 
	cached, 
	isStr, isObj, isFunc, isNum, 
	Cx, domQ, domQall, setClass, toggleClass, hasClass, hasAttr, getAttr, setAttr, 
	Qid, 
	preventQ,
	// noop, 
	// bindFuncs,
	omit,
	makeEl, 
	// detectElementOverflow, 
	// newURL, 
	//getScript, 
	toCapital, 
	getInitials, 
	formikValidClass, 
	getParam, 
	getLocalISOString, 
	isMobile, 
	unescapeHTML, 
};

