import * as requestFromServer from "./applicationMenu";
import * as tenantService from "../service/index.js";
import { appMenuSlice, callTypes } from "./applicationMenuSlice";
// import { menuTitle } from "../pages/tree";

const { actions } = appMenuSlice;

export const fetchTrees = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return tenantService
    .getTree()
    .then(({ data }) => {
      // data.forEach((v) => {
      //   const title = menuTitle(v.icon, v.title);
      //   v.title = title;
      // });

      dispatch(actions.treesFetched({ data }));
    })
    .catch((error) => {
      dispatch(actions.onSnackbarOpen({ message: error.message, type: "error" }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchMenus = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.menu }));
  return requestFromServer
    .getByUserId()
    .then(({ status, data }) => {
      switch (status) {
        case 200:
          dispatch(actions.menusFetched({ data: data.lists }));
          break;
        case 401:
          let error = "401: Unauthorized";
          dispatch(actions.catchError({ error, callType: callTypes.list }));
          break;
        default:
          break;
      }
    })
    .catch((error) => {
      dispatch(actions.onSnackbarOpen({ message: error.message, type: "error" }));
      dispatch(actions.catchError({ error, callType: callTypes.menu }));
    });
};

export const controlMenu = path => dispatch => {
  dispatch(actions.controlMenu({ path }));
}

export const unControlMenu = () => dispatch => {
  dispatch(actions.unControlMenu());
}

export const fetchDetail = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return tenantService
    .getDetail(id)
    .then(({ data }) => {
      if (data.success) {
        const { show_on_seed, id, action_url, icon_class, is_group, menu_name, parent_menu_id, parent_unit_name } = data.data;
        dispatch(actions.detailFetched({
          data: { show_on_seed: show_on_seed || false, id, action_url, icon_class, is_group: is_group || false, menu_name: menu_name || '', parent_menu_id, parent_menu_name: parent_unit_name || '' }
        }));
      } else {
        const error = data.message;
        dispatch(actions.onSnackbarOpen({ message: `ERROR: ${error}` ?? "Unexpected error", type: 'error' }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't load data";
      dispatch(actions.onSnackbarOpen({ message: `ERROR: ${error.message}` ?? "Unexpected error", type: 'error' }));
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}

export const deleteMenu = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return tenantService
    .deleteMenu(id)
    .then(response => {
      const { status, data } = response;
      if (status === 200) {
        if (data.success) {
          dispatch(actions.onSnackbarOpen({ message: "Menu is Deleted", type: "success" }));
        } else {
          dispatch(actions.onSnackbarOpen({ message: `ERROR: ${data.message}` ?? "Unexpected error", type: 'error' }));
        }
      }
      dispatch(actions.clearState());
    })
    .catch(error => {
      dispatch(actions.onSnackbarOpen({ message: `ERROR: ${error.message}`, type: "error" }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

export const orderIndex = data => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return tenantService
    .orderIndex(data)
    .then(response => {
      const { success, message } = response.data;
      if (!success) {
        dispatch(actions.onSnackbarOpen({ message: `ERROR: ${message}` ?? "Unexpected error", type: 'error' }));
      } else {
        dispatch(fetchTrees());
      }
    })
    .catch(error => {
      error.clientMessage = "Can't order index";
      dispatch(actions.onSnackbarOpen({ message: `ERROR: ${error.message}`, type: 'error' }));
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createMenu = menu => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return tenantService
    .createMenu(menu)
    .then(response => {
      // const { data } = response;

      // if (data.status.success) {
      //   dispatch(actions.onSnackbarOpen({ message: `SUCCESS: ${data.status.message}` ?? "Success", type: "success" }));
      // } else {
      //   dispatch(actions.onSnackbarOpen({ message: `ERROR: ${data.status.message}` ?? "Unexpected error", type: 'error' }));
      // }
      dispatch(actions.clearState());
    })
    .catch(error => {
      dispatch(actions.onSnackbarOpen({ message: `ERROR: ${error.message}`, type: "error" }));
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const onSnackbarClose = () => dispatch => {
  dispatch(actions.onSnackbarClose());
  return;
}

export const clear = () => dispatch => {
  dispatch(actions.clearState());
  return;
}