import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";

import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";
import { APP_NAME } from "../../../../data/appData";

export function Brand() {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      // headerLogo: uiService.getLogo(),
      headerLogo: toAbsoluteUrl("/media/logos/FinanSys-Apps-White.svg"), // finansys_flow_white_2.svg
      headerStickyLogo: uiService.getStickyLogo(),
    };
  }, [uiService]);

  return (
    <div
      className={"brand flex-column-auto " + layoutProps.brandClasses} 
      id="kt_brand"
    >
      <Link to="/" className="brand-logo">
        <img
          className="py-3"
          height="60" 
          // loading="lazy" 
          alt={APP_NAME}
          src={layoutProps.headerLogo}
        />
      </Link>

      {layoutProps.asideSelfMinimizeToggle && (
        <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle" type="button">
          <span className="svg-icon svg-icon-xl svg-icon-white">
            <SVG title=" " 
              src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}
            />
          </span>
        </button>
      )}
    </div>
  );
}
