import React, { useEffect } from 'react';
// import { Skeleton } from 'antd';
import { connect } from "react-redux";

import Flex from '../../../components/Flex';
import * as auth from "../_redux/authRedux";

function SsoPage(props) {
    useEffect(() => {
        props.loginMfa();
    }, [props]);// 

    return (
        <Flex justify="center" align="center" className="vh-100">
        </Flex>
    );
}

export default connect(null, auth.actions)(SsoPage);

