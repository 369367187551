// 
// const COMPANY_NAME = 'FinanSys';

const APP_NAME = 'FinanSys Apps';

const FOOTER_LINKS = [
  { href: "https://finansys.com/privacy", label: "Privacy" },
  // { href: "/legal", label: "Legal" },
  { href: "https://finansys.com/contact", label: "Contact" }
];

export {
  // COMPANY_NAME, 
  APP_NAME, 
  FOOTER_LINKS, 
}