/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
*/
import React, { lazy, Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

import { Layout, LayoutSplashScreen } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
// import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import UnderConstruction from "./modules/ErrorsExamples/UnderConstruction";

const ResetPasswordPage = lazy(() => import('./modules/Auth/pages/ResetPassword'));

export function Routes() {
    const { isAuthorized } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.authToken != null,
        }),
        shallowEqual
    );

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <Route path="/reset-password">
					<ResetPasswordPage isAuthorized={isAuthorized} />
				</Route>

                {!isAuthorized ? (
                    /* Render auth page when user at `/auth` and not authorized. */
                    <Route>
                        <AuthPage />
                    </Route>
                ) : (
                    /* Otherwise redirect to root page (`/`) */
                    <Redirect from="/authentication" to="/" />
                )}

                <Route path="/error" component={UnderConstruction} />{/* ErrorsPage */}
                <Route path="/logout" component={Logout} />

                {!isAuthorized ? (
                    /* Redirect to `/auth` when user is not authorized */
                    <Redirect to="/authentication/login" />
                ) : 
                    <Layout>
                        <BasePage />
                    </Layout>
                }

                {/* <Route path="*" component={UnderConstruction} /> */}
            </Switch>
        </Suspense>
    );
}
