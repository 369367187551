import React, { Component } from 'react';
// import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import * as service from "./modules/Auth/_redux/authCrud";
import * as auth from "./modules/Auth/_redux/authRedux";
import { ContentRoute } from "../_metronic/layout";

class AuthorizeRoute extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ready: false,
			authenticated: false
		};
	}

	componentDidMount() {
		this.populateAuthenticationState();
	}
	
	async populateAuthenticationState() {
		const response = await service.validationToken();
		!response && this.props.logout();
		this.setState({ ready: true, authenticated: response ? true : false });
	}

	async authenticationChanged() {
		this.setState({ ready: false, authenticated: false });
		await this.populateAuthenticationState();
	}

	render() {
		const { ready, authenticated } = this.state;
		// console.log(authenticated);
		
		/* const link = document.createElement("a");
		link.href = this.props.path;
		const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
		// eslint-disable-next-line
		const redirectUrl = `/authentication/login?ReturnUrl=${encodeURI(returnUrl)}`; */
		
		if (!ready) {
			return <div />;
		} else {
			const { component: Component, ...rest } = this.props;
			return (
				<ContentRoute 
					{...rest} 
					render={(props) => {
						if (authenticated) {
							return <Component {...props} />
						} 
					}} 
				/>
			)
		}
	}
}

export default connect(null, auth.actions)(AuthorizeRoute);