/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Redirect } from "react-router-dom";// , useHistory
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import { Alert } from 'antd';
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import MfaPage from "./MfaPage";
import SsoPage from "./Sso";
import { shallowEqual, useSelector } from "react-redux";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import AuthLayout from "../../../layouts/AuthLayout";

export function AuthPage() {
  // const history = useHistory();
  const { requiresTwoFactor } = useSelector(
    ({ auth }) => ({
      requiresTwoFactor: auth.requiresTwoFactor,
    }),
    shallowEqual
  );

  const showAlert = ['tenant-management.finansysapps.id'].includes(window.location.hostname)

  return (
    <AuthLayout
      ktLoginStyle={{
        backgroundImage: `url(${toAbsoluteUrl(
          "/media/bg/FinanSys_Flow_landing.png"
        )})`,
      }}
      asideContent={
        <p className="font-weight-bold">
          Accounting and Business Apps to automate your organisation
        </p>
      }
    >
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-4 login-signin-on d-flex flex-row-fluid"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="d-flex flex-center flex-column flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-3.jpg")})`,
            }}
          >
            {showAlert &&
							(<Alert
								message="WARNING!!!!"
								description={`THIS ENVIRONMENT IS NOT TO BE USED FOR DEMO OR TRAINING PURPOSES.\nIT IS ONLY FOR DEVELOPMENT.  FOR DEMO AND TRAINING, PLEASE USE DEMO.FINANSYSAPPS.COM OR FINANSYSTEAM.FINANSYSAPPS.COM`}
								type="warning"
								showIcon
								style={{
									whiteSpace: 'pre-wrap',
									maxWidth: 600,
									marginBottom: 10
								}}
							/>)
						}
            <div
              className={`${!requiresTwoFactor &&
                "login-form text-center p-7"} position-relative overflow-hidden`}
            >
              <div className="d-flex flex-center mb-9">
                {/* mb-15 */}
                <Link to="/" className="flex-column-auto mt-5">
                  <img
                    alt="Logo"
                    className="max-h-70px"
                    src={toAbsoluteUrl("/media/logos/FinanSys-logo.svg")} // FinanSys_Flow_Logo.svg | FinanSys_Flow_Logo.png | logo-letter-13.png
                  />
                </Link>
              </div>
              
              {requiresTwoFactor ? (
                <MfaPage />
              ) : (
                <Switch>
                  <ContentRoute
                    path="/authentication/login"
                    component={Login}
                  />
                  <ContentRoute
                    path="/authentication/forgot-password"
                    component={ForgotPassword}
                  />
                  <ContentRoute
                    path="/authentication/mfa"
                    component={MfaPage}
                  />
                  <ContentRoute
                    path="/authentication/sso"
                    component={SsoPage}
                  />
                  <Redirect
                    from="/authentication"
                    exact={true}
                    to="/authentication/login"
                  />
                  <Redirect to="/authentication/login" />
                </Switch>
              )}
            </div>
          </div>
          {/*end::Login*/}
        </div>
      </div>
    </AuthLayout>
  );
}
