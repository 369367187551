import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';

// import { Cx, hasClass } from '../../utils/Q'; // , hasAttr, isFunc
import { Cx, hasClass, setAttr } from '../../utils/Q';

// ref, strict, 
// export default function Aroute({
const Aroute = forwardRef(
	({
		to, 

		kind, 
		nav, 
		btn, 
		size, 
		outline, 
		dropdown, 
		listGroup, 
		noLine, 
		stretched, 
		className, 
		disabled, 
		// tip, qtip, 
		role, 
		tabIndex, 
		noNewTab, 
		children, 
		// closeDropdown = true, 
		onMouseEnter = () => {}, // Q.noop
		// onMouseLeave = Q.noop, 
		onClick = () => {}, 
		onContextMenu = () => {},
		...etc
	}, 
	ref
) => {
  // const [data, setData] = React.useState();
	// const refA = React.createRef();
	// const getRef = ref || refA;

	const MouseEnter = e => {		
		if(noNewTab){ // et.href
			setAttr(e.target, "href");
		}

		onMouseEnter(e);
	}

/* 	React.useEffect(() => {
		console.log('%cuseEffect in Aroute','color:yellow;');
		console.log(getRef.current);

		if(getRef && disabled){
			getRef.current.addEventListener('auxclick', CtxMenu, false);
		}

		// return () => {
			// if(getRef && disabled){
				// getRef.current.removeEventListener('auxclick', CtxMenu, false);
			// }
		// }

	}, [getRef, disabled, CtxMenu]); */

	// const MouseLeave = e => {
	// 	if(noNewTab) e.target.href = to;

	// 	onMouseLeave(e);
	// }

	const Click = e => {
		const et = e.target;

    if(disabled){
      e.preventDefault();
      return;
    }
		// Close Dropdown menu if component in <Dropdown.Menu />
		// console.log(hasClass(et, "dropdown-item"));
    if(hasClass(et, "dropdown-item")){
			document.body.click();
		}
		
		// let isActive = hasClass(et, "active") || hasAttr(et, 'aria-current');
		// console.log(isActive);
		// console.log("active", hasClass(et, "active"));
		// console.log('aria-current', hasAttr(et, 'aria-current'));
		
    // if(hasClass(et, "active") || hasAttr(et, 'aria-current')){ // aria-expanded="true" | aria-current
		// 	e.preventDefault();
		// 	e.stopPropagation();
		// 	return;
		// }

		// if(isFunc(onClick)) onClick(e);// custom click to props
		onClick(e);
	}

	const CtxMenu = e => {
		// const et = e.target;
		if(disabled){
			e.preventDefault();
			return;
		}

		// if(!et.href) Q.setAttr(et, "href");

		// if(isFunc(onContextMenu)) onContextMenu(e);
		onContextMenu(e);
	}

	return (
		<NavLink 
			{...etc} 
			// ref={ref ? ref : refA} 
			ref={ref} 
			to={to} 
			
			/* isActive={(match, location) => {
				// if(!match){
					// return false;
				// }
				// only consider an event active if its event id is an odd number
				// const eventID = parseInt(match.params.eventID);
				// return !isNaN(eventID) && eventID % 2 === 1;
				console.log(match);
				// isActive
			}}	 */		
			
			onMouseEnter={MouseEnter} 
			// onMouseLeave={MouseLeave} 
			onClick={Click}
			onContextMenu={CtxMenu}

			className={
				Cx(`${btn ? "btn btn" : ""}${btn && outline ? "-outline" : ""}${btn ? "-" + btn : ""}`, {
					"nav-link" : nav,
					// [`btn btn-${btn}`] : btn,
					["btn-" + size] : btn && size, // [`btn-${size}`]
					["text-" + kind] : kind, // [`text-${kind}`]
					"text-decoration-none": noLine, 
					"stretched-link": stretched, 
					"dropdown-item" : dropdown, 
					"list-group-item list-group-item-action": listGroup,
					"disabled" : disabled, 
					"route": !btn && !nav && !noLine && !dropdown && noNewTab
				}, className)
			}
			role={btn && !role ? "button" : role} 
			// title={qtip ? null : tip} 
			// aria-label={(!children || typeof children === 'undefined' || qtip) && tip ? tip : null} 
			tabIndex={disabled && !tabIndex ? "-1" : tabIndex} // {disabled ? "-1" : null}
			aria-disabled={disabled ? true : null}
		>
			{children}
		</NavLink>
	);
});

export default Aroute;


