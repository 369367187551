import axios from "axios";

export const AUTH_URL = "/api/identity/Authentication";

export function login(values) {
  return axios.post(`${AUTH_URL}/authenticate`, values);
}

export function loginWith2Fa(values) {
  return axios.post(`${AUTH_URL}/loginWith2Fa`, values);
}

export function isAuthenticated(){
  return axios.get(`${AUTH_URL}/isAuthenticated`);
}

export function getInfo2fa(){
  return axios.get(`${AUTH_URL}/getInfo2fa`);
}


export function requestPassword(email) {
  return axios.post('', { email });
}

export function forgotPassword(email) {
  return axios.get('/api/email/forgotpassword', { params: { email }});
}

export function validationToken(){
  return axios.post(`${AUTH_URL}/tokenValidation`);
}

