/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import SVG from "react-inlinesvg";

// import * as actions from "../../../../../app/modules/ApplicationMenu/_redux/applicationMenuActions";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  const { menus } = useSelector(
    ({ auth }) => ({
      menus: auth.config.appMenu.lists,
    }),
    shallowEqual
  );

  const GenerateRootMenu = (item) => {
    if (item.items && item.items.length > 0) {
      return (
        <li
          key={`menu_item_rel${item.id}`}
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            item.href
          )}`}
        >
          <NavLink className="menu-link menu-toggle" to={item.href}>
            <span className="menu-text">{item.title}</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              {item.items.map((i) =>
                i.items.length === 0 ? (
                  <li
                    key={`sub_nav_${i.id}`}
                    className={`menu-item ${getMenuItemActive(i.href)}`}
                  >
                    <NavLink className="menu-link" to={i.href}>
                      <span className="menu-text">{i.title}</span>
                    </NavLink>
                  </li>
                ) : (
                  <li
                    key={`sub_nav_${i.id}`}
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/google-material/inputs"
                    )}`}
                    data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    {i.items.map((r) => GenerateMenu(r))}
                  </li>
                )
              )}
            </ul>
          </div>
        </li>
      );
    } else {
      return (
        <li
          key={`menu_item_rel${item.id}`}
          className={`menu-item menu-item-rel ${getMenuItemActive(item.href)}`}
        >
          <NavLink className="menu-link" to={item.href}>
            <span className="menu-text">{item.title}</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
      );
    }
  };

  const GenerateMenu = (item) => {
    return (
      <React.Fragment key={`fragment_${item.id}`}>
        <NavLink className="menu-link menu-toggle" to={item.href}>
          {item.icon && (
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl(item.icon)} />
            </span>
          )}
          <span className="menu-text">{item.title}</span>
          <i className="menu-arrow" />
        </NavLink>
        <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
          <ul className="menu-subnav">
            {item.items && item.items.length > 0 ? (
              GenerateMenu(item)
            ) : (
              <li
                key={`sub_${item.id}`}
                className={`menu-item ${getMenuItemActive(item.href)}`}
              >
                <NavLink className="menu-link" to={item.href}>
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{item.title}</span>
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {menus.map((m) => GenerateRootMenu(m))}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
