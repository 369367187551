import { createSlice } from "@reduxjs/toolkit";
// import { actions } from "../../Auth/_redux/authRedux";

export const callTypes = {
    list: "list",
    action: "action",
    menu: "menu"
};

const initialAppMenuState = {
    listLoading: false,
    actionsLoading: false,
    menuLoading: false,
    appMenuForEdit: {
        menu_name: "",
        action_url: "/",
        icon_class: "/media/svg/icons/Design/Layers.svg",
        is_group: false,
        show_on_seed: false
    },
    trees: [{ key: "", title: "" }],
    menus: [],
    path: null,
    lastError: null,
    statusCode: null,
    snackbarType: "success",
    showSnackbar: false,
};

export const appMenuSlice = createSlice({
    name: "application_menu",
    initialState: initialAppMenuState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.statusCode = action.payload.status;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else if (action.payload.callType === callTypes.menu) {
                state.menuLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            state.statusCode = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else if (action.payload.callType === callTypes.menu) {
                state.menuLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        onSnackbarClose: (state) => {
            state.lastError = null;
            state.showSnackbar = false;
        },
        onSnackbarOpen: (state, action) => {
            state.lastError = action.payload.message;
            state.snackbarType = action.payload.type;
            state.showSnackbar = true;
        },
        treesFetched: (state, action) => {
            const { data } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.trees = data;
        },
        menusFetched: (state, action) => {
            const { data } = action.payload;
            state.menuLoading = false;
            state.error = null;
            state.menus = data;
        },
        detailFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.appMenuForEdit = data;
        },
        controlMenu: (state, action) => {
            const { path } = action.payload;
            state.path = path;
        },
        unControlMenu: (state, action) => {
            state.path = null;
        },
        clearState: (state, action) => {
            state.actionsLoading = false;
            state.appMenuForEdit = {
                menu_name: "",
                action_url: "/",
                icon_class: "/media/svg/icons/Design/Layers.svg"
            };
            state.lastError = null;
            state.statusCode = null;
            state.snackbarType = "success";
            state.showSnackbar = false;
        }
    }
});